@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import '~antd/lib/result/style';
@import '~antd/lib/spin/style';
@import '~antd/lib/empty/style';
@import '~antd/lib/button/style';

.life-journey-widget {
    font-family: "Nunito", sans-serif;
    &__loading {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: sticky;
        & .ant-spin-text {
            margin-top: 8px;
        }
    }
    &-preview {
        position: relative;
        padding: 0 40px;
        display: grid;
        overflow: hidden;
        margin: 0;
        &-overlay {
            overflow: hidden;
            &__content {
                transition: .05s;
            }
        }
        &__arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 0;
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-top: -15px;
            overflow: hidden;
            border-radius: 5px;
            cursor: pointer;
            background-color: #E52127;
            outline: none;
            &_prev {}
            &_next {
                right: 0;
                left: initial;
            }
        }
    }
    &-preview__picture svg {
        & #ranges > *,
        & #pointers > *,
        & #labels > *,
        & #sublabels > * {
            transition: .3s;
            cursor: pointer;
            &:hover {
                opacity: .8;
            }
        }
    }
    &__edit {
        margin-top: 20px;
        display: table;
        margin-left: 40px;
    }
}

.activity-container {
    display: block !important;
}
